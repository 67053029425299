@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css');

html,
body,
#root {
  @apply h-full w-full m-0 p-0;
}

body {
  @apply text-black;
}

* {
  font-family: 'Pretendard Variable', 'sans-serif';
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

@font-face {
  font-family: 'S-CoreDream';
  src: url('../assets/fonts/SCDream2.ttf') format('trueType');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream';
  src: url('../assets/fonts/SCDream3.ttf') format('trueType');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream';
  src: url('../assets/fonts/SCDream4.ttf') format('trueType');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream';
  src: url('../assets/fonts/SCDream5.ttf') format('trueType');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream';
  src: url('../assets/fonts/SCDream6.ttf') format('trueType');
  font-weight: 600;
  font-style: normal;
}

.font-app * {
  font-family: inherit;
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input[type='radio']:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-color: white;
  border-radius: 50%;
}

input[type='checkbox']:checked {
  background-color: transparent; /* 체크 상태의 배경 색상 */
}

input[type='checkbox']:checked::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/checked.svg'); /* 원하는 이미지 경로 */
  background-size: cover; /* 이미지 크기 조정 */
  border-radius: 4px; /* 체크박스와 동일한 테두리 모양 */
}

input[type='checkbox']:focus {
  outline: none; /* 포커스 아웃라인 제거 */
}
